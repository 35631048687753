import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Route, BrowserRouter } from 'react-router-dom'
import './App.css';

const NavBar = () => (
  <div className="navbar">
    <Link to="/">Feed</Link>
    <Link to="/profile">Profile</Link>
  </div>
);

const Template = ({title}) =>
(
  <div>
    <NavBar />
    <p className="page-info">
      This is the {title} page.
    </p>
  </div>
);

const Feed = (props) => (
  <Template title="Feed" />
);

const Profile = (props) => (
  <Template title="Profile" />
);

class App extends Component {
  render() {
    return (
      <p>Hello World</p>
    );
  }
}

export default App;
